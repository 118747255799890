import React from 'react';

const About = () => {
    return (

        <div className="container my-5">
            <h1 className='my-4'>About Us</h1>
            <h3 className="mb-4">OPL’s Advocate Groups: A Primer</h3>

            <p>The Friends of the Oakland Public Library. Branch Friends groups. The Library Commission. Save Oakland Libraries. OPL Advocates. These are some of the groups that advocate on behalf of Oakland Public Library. But what’s the difference, and who does what?</p>

            <p>Let’s start with the essential institution at the heart of all of these groups: Oakland Public Library. OPL is a city agency funded primarily by parcel taxes and the city’s General Fund. The city budget (which includes the library) is decided by the City Council, after the Mayor and City Administrator present a draft budget. This is important, because library funding – both protecting it and increasing it – is often the main focus of all of OPL’s advocacy groups. So knowing who fills the library’s “wallet” is Step One.</p>

            <p>Step Two is building the relationships between the advocate groups. Each group brings unique “superpowers” to the mix. When we understand each other’s strengths, we can join forces and maximize our impact.</p>

            <p>Here’s a list of OPL’s primary advocate groups, with basic details about each one. One thing we all have in common (besides undying love for OPL): We’re all volunteers.</p>

            <h3>City of Oakland Library Commission</h3>
            <ul>
                <li><strong>WHAT:</strong> City commission.</li>
                <li><strong>WHO:</strong> A maximum of 15 commissioners, appointed by City Council to a maximum of two three-year terms.</li>
                <li><strong>MISSION:</strong> Provides mandated oversight of revenue generated by OPL’s two parcel taxes, created by Measures Q and D. They review OPL’s expenditures to ensure the Library Administration follows the guidelines for each parcel tax. Advises Library Administration and elected officials regarding library services. Provides advocacy for OPL staff, programs, and services.</li>
                <li><strong>ADVOCACY STRENGTHS:</strong> Access to elected officials. Access to financial information.</li>
            </ul>

            <h3>Friends of the Oakland Public Library (sometimes called FOPL – but we prefer Friends of the OPL!)</h3>
            <ul>
                <li><strong>WHAT:</strong> City-wide nonprofit fundraising organization that provides substantial grants to benefit all OPL branches. Owner of the Bookmark Bookstore, which supports the Friends through book and media sales. Creator of “Mini-Grants” for OPL staff.</li>
                <li><strong>WHO:</strong> Led by the Friends of the OPL Board of Directors, with a maximum of 20 directors elected to two-year terms. No term limits.</li>
                <li><strong>ADVOCACY STRENGTHS:</strong> Provides funding to cover advocacy expenses. Through Friends members, Bookmark patrons, and volunteers, it supplies a strong base of book and library lovers.</li>
            </ul>

            <h3>Branch Friends Groups</h3>
            <ul>
                <li><strong>WHAT:</strong> Volunteer groups that support individual OPL branches. Branch Friends groups are independent of the system-wide Friends of the OPL; however, Friends support Friends (in both directions) in our shared goal of supporting OPL programs and services.</li>
                <li><strong>WHO:</strong> There are currently about 20 Branch Friends groups! <a href="https://www.fopl.org/partners" target='_blank' rel="noreferrer">Read the list here.</a></li>
                <li><strong>ADVOCACY STRENGTHS:</strong> Raise funds. Possess intimate knowledge of the unique strengths, challenges, and needs of each branch and its community. Provide a volunteer base of “boots on the ground” at key moments, like budget time. Represent the city’s diversity.</li>
            </ul>

            <h3>Youth Leadership Council</h3>
            <ul>
                <li><strong>WHAT:</strong> A youth-driven advisory board for OPL.</li>
                <li><strong>WHO:</strong> Diverse members are ages 13-18.</li>
                <li><strong>ADVOCACY STRENGTHS:</strong> Knowledge of OPL’s teen programs and services. YLC members are trained speakers, and no one gets keener attention from elected officials than they do.</li>
            </ul>

            <h3>Save Oakland Libraries</h3>
            <ul>
                <li><strong>WHAT:</strong> Grass-roots group that can react quickly in clutch moments.</li>
                <li><strong>WHO:</strong> Library staff, patrons, and “rogue advocates.”</li>
                <li><strong>ADVOCACY STRENGTHS:</strong> Knowledge of library programs and usage. Union relationships. Staff can gather stories, bring relationships with Administration, and act as advocacy mentors.</li>
            </ul>

            <h3>SEIU 1021</h3>
            <ul>
                <li><strong>WHAT:</strong> The union that represents the majority of OPL employees.</li>
                <li><strong>WHO:</strong> Representatives of OPL workers or staff.</li>
                <li><strong>ADVOCACY STRENGTHS:</strong> OPL program and staff knowledge; ability to share information among library staff quickly; and city budget expertise.</li>
            </ul>

            <p>Oakland is beyond fortunate to have so many groups working together to support OPL. But there was a time when these groups knew little of each other and couldn’t take advantage of their myriad superpowers. What changed? That’s where <strong>OPL Advocates</strong> comes in.</p>

            <p>Questions? Want to join one of these amazing groups? Contact us at <a href="mailto:OPLAdvocates@gmail.com">OPLAdvocates@gmail.com</a>.</p>
        </div>
    );
};

export default About;